import React from "react";
import Image from "gatsby-plugin-sanity-image";

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import TwitterImage from "../assets/twitter.svg";
import LinkedInImage from '../assets/linkedin.svg';

import "./Person.scss"

const renderMemberLinks = (linkedinUser, twitterUser) => {
  return (
    <>
      <OutboundLink
        href={`https://www.linkedin.com/in/${linkedinUser}`}
        target="_blank" rel="noreferrer noopener"
      >
        <LinkedInImage />
      </OutboundLink>
      {twitterUser &&
        <OutboundLink
          href={`https://twitter.com/${twitterUser}`}
          target="_blank" rel="noreferrer noopener"
        >
          <TwitterImage />
        </OutboundLink>
      }
    </>
  );
};

const Person = (props) => {
  const { image, name, jobtitle, headline, linkedin, twitter, company, team } = props.person
  const imageWidth = props.imageWidth || 150;
  const imageHeight = props.imageHeight || 150;
  return (
    <>
      {image && <Image {...image} width={imageWidth} height={imageHeight} alt="" className="PersonImage" />}
      <div className="PersonInfos">
        {name && <span className="PersonName">{name}</span>}
        {(team || jobtitle) && <span className="PersonTitle">{team || jobtitle} {headline && `/ ${headline}`}</span>}
        {company
        ? <span className="PersonCompany">{company.name}</span>
        : 
        linkedin && <div className="PersonButtons">
          {renderMemberLinks(linkedin, twitter)}
        </div>}
        {props?.children && props.children}
      </div>
    </>
  )
}

export default Person;