import React from 'react';
import { graphql } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import classnames from 'classnames';
import Pancake from './Pancake';

import Button from '../Button';

import BannerImage from '../../assets/banner.svg';

import './PreFooter.scss';

const PreFooter = (props) => {
  const { title, text, callToActions, theme } = props;

  const PreFooterTitle =
    title ||
    'What’s stopping you from transforming your software development process?';
  const PreFooterText =
    text || 'We’re here to help. Let’s discuss your project.';
  const PreFooterCallToActions = callToActions || [
    { label: 'Start for Free', url: '/free-trial/' },
    { label: 'Talk to an expert', url: '/contact/' },
  ];

  const mixpanel = useMixpanel();

  return (
    <Pancake className="PreFooter" theme={theme}>
      <div className="ContainerLegacy">
        <BannerImage className="PreFooterImage" />
        <h2 className="PreFooterTitle">{PreFooterTitle}</h2>
        <p className="PreFooterText">{PreFooterText}</p>
        <div className="PreFooterButtons">
          {PreFooterCallToActions.map((cta, index) => (
            <Button
              className={classnames(
                'with-shadow',
                index === 0 ? 'light with-border' : 'corporate-light'
              )}
              to={cta.url}
              external
              key={index}
              onClick={() => mixpanel.track('Click Start Now Button')}
            >
              {cta.label}
            </Button>
          ))}
        </div>
      </div>
    </Pancake>
  );
};

export const query = graphql`
  fragment SanityPancakePreFooter on SanityPreFooter {
    _key
    _type
    title
    text
    theme
    callToActions {
      callToActions {
        label
        url
      }
    }
  }
`;

export default PreFooter;
