import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Metadata from '../components/Metadata';
import PageLayout from '../layouts/Page';
import Hero from '../components/Hero';
import PreFooter from '../components/pancakes/PreFooter';
import TextPancake from '../components/pancakes/Text';
import Person from '../components/Person';
import Button from '../components/Button';
import Container from '../components/Container';
import Pancake from '../components/pancakes/Pancake';

import './video-page.scss';

export const query = graphql`
  query VideoPageQuery($id: String!) {
    videoPage: sanityVideo(_id: { eq: $id }) {
      _id
      title
      youtubeVideo {
        videoID
      }
      thumbnail {
        image {
          asset {
            url
          }
        }
      }
      tags {
        name
      }
      _rawDescription(resolveReferences: { maxDepth: 1000 })
      participants {
        name
        slug {
          current
        }
        jobtitle
        headline
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

const GetVideoDescriptionFromYoutube = async (youtubeId) => {
  if (!youtubeId) return;
  const data = await fetch('/.netlify/functions/youtube', {
    method: 'POST',
    body: JSON.stringify({
      youtubeId,
    }),
  });

  return data.json();
};

const VideoPage = (props) => {
  const { videoPage } = props.data;

  const hasDescription = videoPage._rawDescription?.length > 0;

  const [videoDescription, setVideoDescription] = useState(
    hasDescription ? videoPage._rawDescription : undefined
  );

  useEffect(() => {
    if (!videoDescription)
      GetVideoDescriptionFromYoutube(videoPage.youtubeVideo?.videoID).then(
        (data) =>
          data ? setVideoDescription(data.description) : setVideoDescription('')
      );
  }, [videoDescription, videoPage.youtubeVideo?.videoID]);

  return (
    <PageLayout>
      <Metadata
        title={`${videoPage.title} | Videos`}
        image={
          videoPage.thumbnail?.image.asset.url ||
          `https://img.youtube.com/vi/${videoPage.youtubeVideo?.videoID}/maxresdefault.jpg`
        }
      />

      <Hero className="VideoPageHero">
        <Hero.Title>
          <span>{videoPage.tags.map((tag) => tag.name).join(', ')}</span>
          {videoPage.title}
        </Hero.Title>
      </Hero>
      <div className="VideoPageIframe">
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoPage.youtubeVideo?.videoID}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      {hasDescription && (
        <TextPancake
          blocks={videoPage._rawDescription}
          className="VideoPageDescription"
          theme="White"
        ></TextPancake>
      )}
      {!hasDescription && (
        <section class="Pancake PancakeText VideoPageDescription PancakeThemeWhite">
          <div class="Container ContainerSizeSmall">
            <div dangerouslySetInnerHTML={{ __html: videoDescription }} />
          </div>
        </section>
      )}
      {videoPage.participants.length > 0 && (
        <Pancake theme="White">
          <Container size="Small">
            <h2>
              {videoPage.participants.length === 1 ? 'Presenter' : 'Presenters'}
            </h2>
            <div className="VideoPageParticipants">
              {videoPage.participants.map((participant) => (
                <div className="VideoPageParticipant">
                  <Person person={participant}>
                    <Button
                      className="corporate-light transparent"
                      to={`/videos/with/${participant.slug.current}/`}
                      arrow
                    >
                      View all videos
                    </Button>
                  </Person>
                </div>
              ))}
            </div>
          </Container>
        </Pancake>
      )}
      <PreFooter theme={hasDescription ? 'Light' : ''} />
    </PageLayout>
  );
};

export default VideoPage;
